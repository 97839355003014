import * as Sentry from '@sentry/react';
import { I18n as I18nClass } from 'i18n-js';
import { invariant } from './invariant';

const translations: Record<string, object> = {};

const localeFilesContext = require.context('../../../config/locales/', false);

for (const key of localeFilesContext.keys()) {
  const module = localeFilesContext(key);

  const language = key.match(/\.\/(countries\.)?(?<code>[a-z]{2})\.yml/)?.groups
    ?.code;

  invariant(language, `Cannot extract language from ${key}`);

  translations[language] = {
    ...translations[language],
    ...module.default[language],
  };
}

const i18n = new I18nClass(translations, {
  // Rails attaches user locale information to `window.cvpI18n`
  // @ts-expect-error: rails
  locale: window.cvpI18n.locale,
  // @ts-expect-error: rails
  defaultLocale: window.cvpI18n.defaultLocale,
});

i18n.missingTranslation.register('sentry', (i18n, i18nScope, options) => {
  Sentry.withScope((scope) => {
    scope.setLevel('warning');
    scope.setExtra('scope', i18nScope);
    scope.captureException(new Error(`Missing translation: "${i18nScope}"`));
  });

  return i18n.missingTranslation.get(i18nScope, {
    ...options,
    missingBehavior: 'message',
  });
});
i18n.missingBehavior = 'sentry';

export const I18n = i18n;
